let model = {};

// dữ liệu form
model.dataForm = {
  id: undefined, // optional - Mã tự sinh
  index: undefined, // optional - Số thứ tự
  name: undefined, // required - Tên Service Health
  icon_id: undefined, // optional, ref: mushroom_file - File icon
  // optional - Thông tin link
  link_info: {
    target_domain: undefined, // required, enum(primary, probe, bras) - Phân hệ
    target_type: undefined, // required, enum(static, service, table_screen, usecase) - Phân loại trang đích
    target_id: undefined, // optional, ref: service|table_screen|usecase - Không bắt buộc với target_type=static, còn lại là bắt buộc
    link: undefined, // required - Đường dẫn menu
  },
  default_chart_type: undefined, // optional, enum(line, bar) - Loại chart mặc định
  // optional - Các thuộc tính của line-chart
  line_chart: {
    tooltip: undefined, // optional - Tooltip
    sql: undefined, // required - câu truy vấn để lấy dữ liệu
    sql_table_formats: [], // optional - câu truy vấn con trong biến @table dạng select..from @table_name where...
    table_config_id: undefined, // required, ref: table_config - Mã của table_config
    service_id: undefined, // optional, ref: service - Id của service
  },
  // optional - Các thuộc tính của bar-chart
  bar_chart: {
    tooltip: undefined, // optional - Tooltip
    sql: undefined, // required - câu truy vấn để lấy dữ liệu
    sql_table_formats: [], // optional - câu truy vấn con trong biến @table dạng select..from @table_name where...
    table_config_id: undefined, // required, ref: table_config - Mã của table_config
    service_id: undefined, // optional, ref: service - Id của service
  },
  // optional - các thuộc tính của table
  table: {
    tooltip: undefined, // optional - Tooltip
    sql: undefined, // required - câu truy vấn để lấy dữ liệu
    sql_table_formats: [], // optional - câu truy vấn con trong biến @table dạng select..from @table_name where...
    table_config_id: undefined, // required, ref: table_config - Mã của table_config
    service_id: undefined, // optional, ref: service - Id của service
  },
  created_time: undefined, // required - Thời điểm tạo
  creator_id: undefined, // required, ref: user - Mã người tạo
  last_modified_time: undefined, // required - Thời điểm sửa cuối
  last_modifier_id: undefined, // required, ref: user - Mã người sửa cuối
};
model.targetDomains = [
  { value: "primary", label: "Primary" },
  { value: "probe", label: "Probe" },
  { value: "bras", label: "Bras" },
];
model.targetType = [
  { value: "static", label: "Static" },
  { value: "service", label: "Service" },
  { value: "table_screen", label: "Table screen" },
  { value: "usecase", label: "Usecase" },
  { value: "dynamic", label: "Dynamic" },
];
model.listTypeChart = [
  {
    value: "line",
    label: "Line",
  },
  {
    value: "bar",
    label: "Bar",
  },
];

model.tableRules = {
  lengthMenu: [12, 24, 36, 48],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 24,
  offset: 0,
  sort: "",
  defaultSort: ["", ""],
  filters: "",
};

export default model;
