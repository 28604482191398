<template>
  <div
    :id="'line-chart-' + props.id"
    style="width: 100%; height: 100%"
    class="chart-div"
  ></div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import * as echarts from "echarts";

const props = defineProps({
  id: String,
  itemData: Object,
});
const nameColumns = ref([]);

const renderColumns = () => {
  const cols = props.itemData?.data?.map((row) => row[0]) ?? [];

  return cols;
};
const render = (data) => {
  if (!data) return data;
  const chartDom = document.getElementById(`line-chart-${props.id}`);
  const myChart = echarts.init(chartDom);
  const option = {
    grid: {
      top: 10,
      bottom: 0,
      left: 0,
      right: 0,
      // containLabel: true,
    },
    xAxis: {
      type: "category",
      data: renderColumns(),
      show: false,
    },
    yAxis: {
      type: "value",
      show: true,
      offset: 1000, //-> bình thường bằng 0, tăng lên để tràn quá thẻ div
      splitLine: {
        lineStyle: {
          color: "#D9E5FF",
          type: "dashed",
          width: 0.5,
        },
      },
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "#1c1c1c",
      borderColor: "#1C1C1C",
      borderRadius: 14,
      textStyle: {
        color: "#fff",
        fontSize: 12,
        fontFamily: "sans-serif",
      },
      formatter: (p) =>
        p[0].name == "" && p[0].value === undefined
          ? undefined
          : `${
              nameColumns.value?.length > 1
                ? `  ${nameColumns.value?.[0]?.code}: ${p[0].name}`
                : `${nameColumns.value?.[0]?.code}`
            }<br/> <i style='display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #A6C1FF; margin-right: 5px'></i> ${
              nameColumns.value?.length > 1
                ? `${nameColumns.value?.[1]?.code}:`
                : ""
            } <span style='font-weight: bold; font-family: sans-serif'>${
              p[0].value ?? ""
            }</span>`,
    },
    series: [
      {
        data: data?.data?.map((row) => row[row.length === 1 ? 0 : 1]),
        type: "line",
        showSymbol: false,
        lineStyle: {
          width: 0.57,
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#A6C1FF",
            },
            {
              offset: 0.998,
              color: "#fff",
            },
          ]),
        },
        smooth: true,
      },
    ],
    color: "#407BFF",
  };
  myChart.setOption(option);
};

onMounted(() => {
  nameColumns.value = props.itemData?.columns;

  render(props.itemData);
});
</script>
