<template>
  <div class="health">
    <div class="health__header">
      <a
        v-if="linkUrl"
        :href="linkUrl"
        target="_blank"
        class="health__header--left"
        :class="{
          width1: storeChangeEdit.showEdit || typeChart,
          width2: storeChangeEdit.showEdit && typeChart,
        }"
      >
        <b-skeleton-wrapper :loading="loadingSkeleton">
          <template #loading>
            <b-skeleton type="avatar"></b-skeleton>
            <b-skeleton height="18px"></b-skeleton>
          </template>
          <img v-if="srcIcon.logo" :src="srcIcon.logo" alt="logo" />
          <img v-else :src="NOLOGO" alt="logo default" height="32" />
          <span :title="props.dataItem.name">{{
            props.dataItem.name
          }}</span></b-skeleton-wrapper
        >
      </a>
      <div
        v-else
        class="health__header--left"
        :class="{
          width1: storeChangeEdit.showEdit || typeChart,
          width2: storeChangeEdit.showEdit && typeChart,
        }"
      >
        <b-skeleton-wrapper :loading="loadingSkeleton">
          <template #loading>
            <b-skeleton type="avatar"></b-skeleton>
            <b-skeleton height="18px"></b-skeleton>
          </template>
          <img v-if="srcIcon.logo" :src="srcIcon.logo" alt="logo" />
          <img v-else :src="NOLOGO" alt="logo default" height="32" />
          <span :title="props.dataItem.name">{{
            props.dataItem.name
          }}</span></b-skeleton-wrapper
        >
      </div>
      <div class="health__header--right">
        <img
          v-if="storeChangeEdit.showEdit"
          :src="SETTING"
          alt="setting"
          :title="$t('t-settings')"
          @click="showUpdateForm"
          height="32"
        />
        <div v-if="typeChart" class="health__header--right--optionChart">
          <img
            :src="BARCHART"
            alt="setting"
            v-if="typeChart === 'bar'"
            :title="$t('t-switch-to-line-chart')"
            @click="changeTypeChart"
            height="32"
          />
          <img
            :src="LINECHART"
            alt="setting"
            v-if="typeChart === 'line'"
            :title="$t('t-switch-to-bar-chart')"
            @click="changeTypeChart"
            height="32"
          />
        </div>
      </div>
    </div>

    <div class="health__body">
      <div class="health__body--delay">
        <a
          :href="getQuery(dataInfoServiceHealthOne)"
          style="width: 50%"
          target="_blank"
        >
          <div class="health__body--delay--kpi">
            <div
              class="health__body--delay--kpi--name"
              :title="props.data?.dataKPI?.[0]?.tooltip ?? ''"
            >
              <b-skeleton-wrapper :loading="loadingSkeleton">
                <template #loading>
                  <b-skeleton width="55%" height="20px"></b-skeleton>
                </template>
                {{ props.data?.dataKPI ? props.data.dataKPI[0]?.label : "" }}
              </b-skeleton-wrapper>
            </div>
            <b-skeleton-wrapper :loading="loadingSkeleton">
              <template #loading>
                <b-skeleton width="55%" height="30px"></b-skeleton>
              </template>
              <div
                class="health__body--delay--kpi--value"
                v-if="props.data?.dataKPI?.length > 0"
                :style="{ color: props.data.dataKPI?.[0]?.color || '#000' }"
                :title="props.data.dataKPI?.[0]?.tooltip ?? ''"
              >
                {{ props.data.dataKPI?.[0]?.value ?? "" }}
                {{
                  props.data.dataKPI?.[0]?.value == "NA"
                    ? ""
                    : props.data.dataKPI?.[0]?.unit ?? ""
                }}
              </div></b-skeleton-wrapper
            >
          </div>
        </a>

        <div class="health__body--delay--chart">
          <b-skeleton-wrapper :loading="loadingSkeleton">
            <template #loading>
              <b-skeleton width="100%" height="56px"></b-skeleton>
            </template>
            <div class="status-data">
              <!-- <div
                class="status-data__item"
                v-if="statusDataChart === 'loading-data'"
              >
                <i class="ri-information-line no-data"></i>
                <p>Đang tải dữ liệu</p>
              </div> -->
              <div
                class="status-data__item"
                v-if="statusDataChart === 'no-data'"
              >
                <i class="ri-information-line no-data"></i>
                <p>{{$t('t-no-data')}}</p>
              </div>
              <div
                class="status-data__item"
                v-if="statusDataChart === 'error-data'"
              >
                <i class="ri-information-line error-data"></i>
                <p>{{$t('t-get-data-error')}}</p>
              </div>
            </div>
            <line-chart
              v-if="typeChart === 'line'"
              :id="props.data.id"
              :itemData="dataChart"
              :key="rerenderLineChart"
            ></line-chart>
            <bar-chart
              v-if="typeChart === 'bar'"
              :id="props.data.id"
              :itemData="dataChart"
              :key="rerenderBarChart"
            ></bar-chart
          ></b-skeleton-wrapper>
        </div>
      </div>
      <div class="health__body--info">
        <b-skeleton-wrapper :loading="loadingSkeleton">
          <template #loading>
            <b-skeleton
              width="100%"
              height="25px"
              style="margin-bottom: 5px"
            ></b-skeleton>
            <b-skeleton
              width="100%"
              height="25px"
              style="margin-bottom: 5px"
            ></b-skeleton>
            <b-skeleton
              width="100%"
              height="25px"
              style="margin-bottom: 5px"
            ></b-skeleton>
            <b-skeleton
              width="100%"
              height="25px"
              style="margin-bottom: 5px"
            ></b-skeleton>
          </template>
          <div class="status-data" v-if="dataInfoServiceHealth.length === 0">
            <div class="status-data__item" v-if="statusDataKPI === 'no-data'">
              <i class="ri-information-line no-data"></i>
              <p>{{$t('t-no-data')}}</p>
            </div>
            <div
              class="status-data__item"
              v-if="statusDataKPI === 'error-data'"
            >
              <i class="ri-information-line error-data"></i>
              <p>{{$t('t-get-data-error')}}</p>
            </div>
          </div>
          <div class="health__body--info--block" v-else>
            <div
              v-for="item in dataInfoServiceHealth"
              :key="item.id"
              class="health__body--info--item"
              :title="item.tooltip"
            >
              <a :href="getQuery(item)" target="_blank">
                <div class="health__body--info--name">
                  <img v-if="item.icon" :src="item.icon" alt="" />
                  <img v-else :src="NOICON" alt="" height="32" />
                  <div class="label-name">{{ item.label || "" }}</div>
                </div>
                <div
                  class="health__body--info--value"
                  :style="{ color: item.color || '#000' }"
                >
                  {{ item.value }} {{ item.value !== "NA" ? item.unit : "" }}
                </div>
              </a>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>
<script setup>
import mushroom from "cem-probe-api";
import { defineEmits, defineProps, onMounted, reactive, ref, watch } from "vue";
import { changeEdit } from "@/stores/changeEdit";
const storeChangeEdit = changeEdit();
import { selectVlan } from "@/stores/changeSelectVlan";
const storeSelectVlan = selectVlan();
import NOLOGO from "@/assets/icon/icon-service-health/no-logo.png";
import NOICON from "@/assets/icon/icon-service-health/no-icon.png";
import SETTING from "../../assets/icon/icon-service-health/setting.png";
import BARCHART from "../../assets/icon/icon-service-health/bar-chart.png";
import LINECHART from "../../assets/icon/icon-service-health/line-chart.png";
import LineChart from "./line-chart.vue";
import BarChart from "./bar-chart.vue";
import { useRoute } from "vue-router";
import { datePicker } from "@/stores/datePicker";
import MethodService from "@/service/MethodService";
import { abortAPI } from "@/stores/abortAPI";

const props = defineProps({
  data: Object,
  dataItem: Object,
  dataKPI: Array,
  showEdit: Boolean,
  loadingSkeleton: Boolean,
});
const emit = defineEmits([""]);
const storeDatePicker = datePicker();
const typeChart = ref(null);
const rerenderBarChart = ref(0);
const rerenderLineChart = ref(0);
const dataInfoServiceHealth = ref([]);
const dataInfoServiceHealthOne = ref({});
const route = useRoute();
const dataChart = ref(null);
const dataChartOld = ref(null);
const dataChartOldSecond = ref(null);
const typeChartOld = ref("");
const dataKPI = ref([]);
// const query = ref('');
const linkUrl = ref(null);
const srcIcon = reactive({
  logo: null,
});
const statusDataChart = ref("loading-data");
const statusDataKPI = ref("loading-data");
const abortController = new AbortController();
const storeAbortAPI = abortAPI();

const changeTypeChart = async () => {
  typeChart.value === "line"
    ? (typeChart.value = "bar")
    : (typeChart.value = "line");

  if (typeChartOld.value === typeChart.value) {
    if (!dataChartOld.value) {
      statusDataChart.value = "error-data";
      dataChart.value = null;
    } else if (dataChartOld.value.length === 0) {
      statusDataChart.value = "no-data";
      dataChart.value = null;
    } else {
      statusDataChart.value = null;
      dataChart.value = dataChartOld.value;
    }
    rerenderBarChart.value += 1;
    rerenderLineChart.value += 1;
    return;
  }
  if (dataChartOldSecond.value) {
    statusDataChart.value = null;
    dataChart.value = dataChartOldSecond.value;
    rerenderBarChart.value += 1;
    rerenderLineChart.value += 1;
    return;
  }

  try {
    const dataObject = {
      probe_id: localStorage.getItem("pid"),
      service_health_id: props.data?.id,
      start_date: storeDatePicker.showDatePicker[0],
      stop_date: storeDatePicker.showDatePicker[1],
      chart_type: typeChart.value,
      vlan_id:
        storeSelectVlan.getValueVlan === "all"
          ? undefined
          : Number(storeSelectVlan.getValueVlan),
    };
    let resChart = await mushroom.probe_data.getServiceHealthChartAsync({
      body: dataObject,
      settings: {
        abortController,
      },
    });
    if (!resChart.result) {
      statusDataChart.value = "error-data";
      dataChart.value = null;
    } else if (resChart.result?.length === 0) {
      statusDataChart.value = "no-data";
      dataChart.value = null;
    } else {
      statusDataChart.value = null;
      dataChart.value = resChart.result;
      dataChartOldSecond.value = resChart.result;
    }
  } catch (e) {
    statusDataChart.value = "error-data";
    dataChart.value = null;
  }
  rerenderBarChart.value += 1;
  rerenderLineChart.value += 1;
};
const showUpdateForm = () => {
  emit("show-dialog-update", props.data);
};

const getQuery = (value) => {
  if (value && value.link) {
    const IdKPI = dataKPI.value.find((item) => item.code === value.code)?.id;
    const domanin = dataKPI.value.find(
      (item) => item.code === value.code
    )?.target_domain;
    let query = `id=${props.data?.id}&kpi_id=${IdKPI}&kpi_code=${
      value.code
    }&kpi_value=${value.value}&start_date=${MethodService.formatDateYYYYMMDD(
      storeDatePicker.showDatePicker[0]
    )}&stop_date=${MethodService.formatDateYYYYMMDD(
      storeDatePicker.showDatePicker[1]
    )}`;

    let linkFirst = value.link;
    let link = value.link.slice(0, value.link.length - 1);
    let linkLast = "";

    if (value.link.includes("#")) {
      linkFirst = value.link.slice(0, value.link.indexOf("#"));
      linkLast = value.link.slice(value.link.indexOf("#"));
      link = link.slice(0, link.indexOf("#"));
    }
    if (route.query.vlan_id !== "all" && route.query.vlan_id)
      query += `&vlan_id=${route.query.vlan_id}`;

    if (value.link.slice(-1) === "/" && value.link.includes("?")) {
      const url =
        domanin === "primary"
          ? localStorage.getItem("urlPrimary") +
            link +
            `&${query}` +
            "/" +
            linkLast
          : link + `&${query}` + "/" + linkLast;
      return url;
    } else if (value.link.slice(-1) === "/") {
      const url =
        domanin === "primary"
          ? localStorage.getItem("urlPrimary") +
            link +
            `?${query}` +
            "/" +
            linkLast
          : link + `?${query}` + "/" + linkLast;
      return url;
    } else if (value.link.includes("?")) {
      const url =
        domanin === "primary"
          ? localStorage.getItem("urlPrimary") +
            linkFirst +
            `&${query}` +
            linkLast
          : linkFirst + `&${query}` + linkLast;
      return url;
    } else {
      const url =
        domanin === "primary"
          ? localStorage.getItem("urlPrimary") +
            linkFirst +
            `?${query}` +
            linkLast
          : linkFirst + `?${query}` + linkLast;
      return url;
    }
  }
  return null;
};

watch(
  () => props.data?.dataChart,
  (newData) => {
    dataChart.value = newData;
  }
);
watch(
  () => props.data?.default_chart_type,
  (newType) => {
    typeChart.value = newType;
  }
);
watch(
  () => props.dataKPI,
  (newData) => {
    dataKPI.value = newData;
  }
);
const oldWidth = ref(0);
const newWidth = ref(0);
const reRenderChart = () => {
  newWidth.value = window.innerWidth;
  if (oldWidth.value !== newWidth.value) {
    rerenderBarChart.value += 1;
    rerenderLineChart.value += 1;
    oldWidth.value = newWidth.value;
  }
};
onMounted(() => {
  storeAbortAPI.setAbort(abortController);
  window.addEventListener("resize", reRenderChart);
  if (
    props.data?.link_info?.target_domain &&
    props.data?.link_info?.link &&
    props.data?.link_info?.target_type
  ) {
    let strUrlPrimary = localStorage.getItem("urlPrimary");
    let queryLink =
      "&start_date=" +
      MethodService.formatDateYYYYMMDD(storeDatePicker.showDatePicker[0]) +
      "&stop_date=" +
      MethodService.formatDateYYYYMMDD(storeDatePicker.showDatePicker[1]);
    if (storeSelectVlan.getValueVlan !== "all")
      queryLink += "&vlan_id=" + Number(storeSelectVlan.getValueVlan);

    const url = new URL(
      "https://test/" +
        (props.data.link_info.link[0] === "/"
          ? props.data.link_info.link.slice(1)
          : props.data.link_info.link)
    );

    if (props.data.link_info.target_domain === "primary") {
      if (props.data.link_info.link[0] === "#") {
        linkUrl.value = props.data.link_info.link;
      } else if (
        props.data.link_info.link[0] !== "#" &&
        props.data.link_info.target_type === "static"
      ) {
        linkUrl.value = strUrlPrimary + props.data.link_info.link;
      } else
        linkUrl.value =
          strUrlPrimary +
          url.pathname +
          url.search +
          `${url.search ? "&" : "?"}id=${props.data.id}` +
          queryLink +
          url.hash;
    } else {
      if (props.data.link_info.link[0] === "#") {
        linkUrl.value = props.data.link_info.link;
      } else
        linkUrl.value =
          url.pathname +
          url.search +
          `${url.search ? "&" : "?"}id=${props.data.id}` +
          queryLink +
          url.hash;
    }
  }
  typeChart.value = props.data?.default_chart_type;
  typeChartOld.value = props.data?.default_chart_type;

  if (!props.data?.dataChart) {
    statusDataChart.value = "error-data";
  } else if (props.data?.dataChart?.length === 0) {
    statusDataChart.value = "no-data";
  } else {
    statusDataChart.value = null;
    dataChart.value = props.data?.dataChart;
    dataChartOld.value = props.data?.dataChart;
  }

  if (props.data?.icon_id)
    srcIcon.logo = MethodService.thumbIcon(props.data.icon_id, 32);
  statusDataKPI.value = "";
  if (!props.data?.dataKPI) {
    statusDataKPI.value = "error-data";
  } else if (props.data?.dataKPI.length === 0) {
    statusDataKPI.value = "no-data";
  } else {
    dataInfoServiceHealth.value = [...props.data?.dataKPI];
    dataInfoServiceHealthOne.value = props.data?.dataKPI[0];
  }
  dataKPI.value = props.dataKPI;
});
</script>

<style scoped lang="scss">
.health {
  padding: 16px;
  min-width: 264px;
  height: 264px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.03);
  border-radius: 24px;
  &__header {
    line-height: 32px;
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--left {
      display: flex;
      align-items: center;
      max-width: 100%;
      padding-right: 5px;
      img {
        padding-right: 8px;
      }
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #494949;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .b-skeleton {
        width: 130px;
      }
      .b-skeleton-avatar {
        margin-right: 10px;
        width: 32px;
        height: 32px;
      }
    }
    &--right {
      max-width: 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        cursor: pointer;
      }
      &--optionChart {
        padding-left: 8px;
      }
    }
  }
  &__body {
    padding-top: 8px;
    width: 100%;
    &--delay {
      height: 64px;
      display: flex;
    }
    &--delay--kpi {
      width: 100%;
      padding: 6px 0;
      &--name {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #a4a4a4;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
      &--value {
        margin-top: 8px;
        padding: 4px;
        width: fit-content; // 74px
        height: 32px;
        background: rgba(161, 227, 203, 0.2);
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 27px;
        color: #50e7b0;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        .b-skeleton {
          width: 80px;
        }
      }
    }
    &--delay--chart {
      width: 50%;
      padding: 4px 0;
      display: flex;
      justify-content: flex-end;
      position: relative;
      .status-data {
        width: 100%;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        p {
          font-size: 12px;
        }
        i {
          font-size: 25px;
        }
        i.no-data {
          color: #e6a23c;
        }
        i.error-data {
          color: #f56c6c;
        }
      }
    }
    &--info--item {
      &:last-child {
        padding-bottom: 0;
      }
      a {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
      }
    }
    &--info {
      margin-top: 14px;
      max-height: 128px;
      overflow-y: auto;
      overflow-x: hidden;
      // margin-right: -10px; để hiển thị thêm thanh cuộn dọc
      position: relative;
      &::-webkit-scrollbar-thumb {
        background-color: #d2d2d2;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      &--item {
        margin-right: 5px;
      }
      .status-data {
        max-height: 118px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        p {
          font-size: 12px;
        }
        i {
          font-size: 25px;
        }
        i.no-data {
          color: #e6a23c;
        }
        i.error-data {
          color: #f56c6c;
        }
      }
    }
    &--info--block {
      margin-right: 10px;
      .b-skeleton-text {
        margin-bottom: 0;
      }
    }
    &--info--name {
      max-width: calc(100% - 145px);

      img {
        padding-right: 9.35px;
        height: 16px;
        object-fit: contain;
      }
      .label-name {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #a4a4a4;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      display: flex;
      align-items: center;
    }
    &--info--value {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      min-width: 116px;
      max-width: 140px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: end;
    }
  }
}
</style>
