<template>
  <div
    :id="'line-chart-' + props.id"
    style="width: 100%; height: 100%"
    class="chart-div"
  ></div>
</template>

<script setup>
import { defineProps, onMounted, ref, watch } from "vue";
import * as echarts from "echarts";
import MethodService from "@/service/MethodService";

const props = defineProps({
  id: String,
  itemData: Object,
});
const dataForSeries = ref([]);
const typeDataIsDateTime = ref(null);
const nameColumns = ref([]);
const renderColumns = () => {
  const cols = props.itemData?.data?.map((row) => row[0]) ?? [];
  while (cols.length < 20) {
    cols.unshift("");
  }
  return cols;
};
const upSizeData = (data) => {
  if (!data?.data?.length) return;
  dataForSeries.value = data.data.map((col) => col[col.length === 1 ? 0 : 1]);
  if (typeDataIsDateTime.value) return;
  while (dataForSeries.value.length < 20) {
    dataForSeries.value.unshift(undefined);
  }
};

const render = () => {
  const chartDom = document.getElementById(`line-chart-${props.id}`);
  const myChart = echarts.init(chartDom);
  const option = {
    grid: {
      top: 0,
      bottom: 0,
      // left: 0,
      right: 0,
      // containLabel: true,
    },
    xAxis: {
      type: typeDataIsDateTime.value ? "time" : "category",
      data: renderColumns(),
      show: false,
    },
    yAxis: {
      type: "value",
      show: false,
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "#1c1c1c",
      borderColor: "#1C1C1C",
      borderRadius: 14,
      textStyle: {
        color: "#fff",
        fontSize: 12,
        fontFamily: "sans-serif",
      },
      formatter: (p) =>
        p[0].name == "" && p[0].value === undefined
          ? undefined
          : `${
              nameColumns.value?.length > 1
                ? `  ${nameColumns.value?.[0]?.code}: ${p[0].name}`
                : `${nameColumns.value?.[0]?.code}`
            }<br/> <i style='display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #A6C1FF; margin-right: 5px'></i> ${
              nameColumns.value?.length > 1
                ? `${nameColumns.value?.[1]?.code}:`
                : ""
            } <span style='font-weight: bold; font-family: sans-serif'>${
              p[0].value ?? ""
            }</span>`,
    },
    series: [
      {
        data: dataForSeries.value,
        type: "bar",
        itemStyle: {
          borderRadius: [5, 5, 5, 5],
          color: "#A6C1FF",
        },
      },
    ],
  };
  myChart.setOption(option);
};

watch(
  () => props.itemData,
  () => {
    upSizeData(props.itemData);
  }
);

onMounted(() => {
  if (props.itemData?.columns?.length > 1) {
    if (
      MethodService.checkFormatDateYYYYMMDD(props.itemData?.data?.[0]?.[1]) ||
      MethodService.checkFormatDateTime(props.itemData?.data?.[0]?.[1])
    ) {
      typeDataIsDateTime.value = true;
    }
  }
  nameColumns.value = props.itemData?.columns;
  upSizeData(props.itemData);
  render();
});
</script>
